// Recorder Event Handler, when media is ready/recorded
export type MediaHandler = (data: Blob) => any

// Create a new media recorder, with simple start/stop controls
//
// The event handler is attached to the start action.
export async function NewRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
  const recorder = new MediaRecorder(stream, {mimeType: 'audio/webm'})

  let chunks: any[] = []
  let action: MediaHandler|null = null
  let lastRecording: Blob|null = null
  recorder.addEventListener('dataavailable', (e) => {
    if (e.data.size > 0) {
      chunks.push(e.data)
    }
  })
  recorder.addEventListener('stop', (e) => {
    lastRecording = new Blob(chunks)
    if (action) {
      action(lastRecording)
      lastRecording = null
    }
    action = null
    chunks = []
  })

  return {
    stream, 
    
    start(_action: MediaHandler|null = null) {
      action = _action
      recorder.start()
    },

    stop() {
      recorder.stop()
    },

    recording() {
      const r = lastRecording
      lastRecording = null
      return r
    },
  }
}