import { log, completer, Completer, safeResult } from "./util"
import { NewSessionRequest, NewSessionResponse, RenegotiateRequest, RenegotiationResponse, TracksRequest, TracksResponse } from "./callsApi"
import { CallStream, Calls } from "./calls"
import { Observable } from "babylonjs"

declare global {
  interface Window { 
    socket: WebSocket
  }
}

export interface RoomClient {
  on(listeners: Record<string, Function>): void
  emit(event:string, data: any): void
  close(): void
}

export function connect(room: string, handlers: Record<string, Function>): RoomClient {
  log("connecting to room", room)
  // If we are running via wrangler dev, use ws:
  const url = new URL(document.URL)
  url.protocol = url.protocol === "http:" ? "ws:" : "wss:"
  url.pathname = `/api/room/${room}`
  const socket = new WebSocket(url.toString())
  window.socket = socket
  const start = Date.now()

  function setHandlers(added: Record<string, Function>) {
    Object.assign(handlers, added)
  }

  function emit(event: string, data: any) {
    socket.send(JSON.stringify({_type: event, ...data}))
  }

  function close() {
    socket.close(1234,"done")
  }

  function on(listeners: Record<string, (event: Event) => void>) {
    for (const [name, handler] of Object.entries(listeners)) {
      socket.addEventListener(name, handler)
    }
  }

  // attach listeners
  on({
    open(event) {
      log('opened')
    },

    message(event) {
      let data = JSON.parse((event as MessageEvent).data);
      const _type = data._type
      const f = handlers[_type] || handlers['_else']
      f && safeResult(f(data))
    },

    close(ev) {
      const event = ev as CloseEvent
      log("closed", event.code, event.reason)
    },

    error(event) {
      log("error", event);
    }
  })

  return {
    on: setHandlers,
    emit,
    close,
  }
}
window.connect = connect

export type RoomStream = {
  clientId: number
  stream: MediaStream
}

// adaptor to propagate Calls Sessino API calls through game socket
export class RoomCalls {
  calls: Calls
  room: RoomClient
  sessionCalls: Record<number, Completer<NewSessionResponse>> = {}
  trackCalls: Record<number, Completer<TracksResponse>> = {}
  renegotiateCalls: Record<number, Completer<RenegotiationResponse>> = {}
  streamsObservable: Observable<RoomStream>

  constructor(room: RoomClient) {
    this.streamsObservable = new Observable()
    this.calls = new Calls({
      session: (req: NewSessionRequest): Promise<NewSessionResponse> => {
        const id = Date.now()
        log('calls session =>', id)
        this.room.emit('calls_session', {id, req})
        return (this.sessionCalls[id] = completer())
      },
      tracks: (sessionId: string, req: TracksRequest): Promise<TracksResponse> => {
        const id = Date.now()
        log('calls tracks =>', id, sessionId)
        this.room.emit('calls_tracks', {id, sessionId, req})
        return (this.trackCalls[id] = completer())
      },
      renegotiate: (sessionId: string, req: RenegotiateRequest): Promise<RenegotiationResponse> => {
        const id = Date.now()
        log('calls reneg =>', id, sessionId)
        this.room.emit('calls_renegotiate', {id, sessionId, req})
        return (this.renegotiateCalls[id] = completer())
      },
    })
    this.room = room
    room.on({
      calls_session: ({id, res}: {id: number, res: NewSessionResponse}) => {
        log('calls session <=', id, res.sessionId)
        const call = this.sessionCalls[id]
        if (call) {
          delete this.sessionCalls[id]
          call.complete(res)
        }
      },
      calls_tracks: ({id, res}: {id: number, res: TracksResponse}) => {
        log('calls tracks <=', id, res.errorCode)
        const call = this.trackCalls[id]
        if (call) {
          delete this.trackCalls[id]
          call.complete(res)
        }
      },
      calls_newtracks: async ({clientId, stream}: {clientId: number, stream: CallStream}) => {
        log('calls newtracks <=', clientId)
        const mediaStream = await this.calls.getStream(stream)
        log('calls newtracks <=', clientId, mediaStream.id)
        this.streamsObservable.notifyObservers({ clientId, stream: mediaStream })
      },
      calls_renegotiate: ({id, res}: {id: number, res: RenegotiationResponse}) => {
        log('calls reneg <=', id, res.errorCode)
        const call = this.renegotiateCalls[id]
        if (call) {
          delete this.renegotiateCalls[id]
          call.complete(res)
        }
      }
    })
  }

  async init(microphone: MediaStream) {
    log('room calls init', microphone.id)
    await this.calls.init()
    await this.calls.sendStream(microphone)
  }
}
window.RoomCalls = RoomCalls