import { AbstractMesh, ActionManager, ExecuteCodeAction, Mesh, Observer, Ray, Scene, WebXRAbstractMotionController, WebXRControllerComponent, WebXRInput, WebXRInputSource } from "babylonjs";
import {log } from './util'

export type InputFunc = (state: WebXRControllerComponent, controller: WebXRInputSource) => void

export type Button = {
  changed?: InputFunc
  pressed?: InputFunc
  release?: InputFunc
}

export type InputMap = {
  left?: {
    trigger?: Button
    hold?: Button
    x?: Button
    y?: Button
  }
  right?: {
    trigger?: Button
    hold?: Button
    a?: Button
    b?: Button
  }
}

type Disposer = () => void

export class InputRouter {
  controllers: Record<string, WebXRInputSource> = {}
  map?: InputMap
  left?: AbstractMesh
  right?: AbstractMesh
  disposers: Disposer[] = []
  controller?: WebXRInputSource
  ray: Ray = Ray.Zero()
  onMove?: ((ray: Ray) => void)
  
  constructor(input: WebXRInput) {
    input.onControllerAddedObservable.add(controller => {
      controller.onMotionControllerInitObservable.add(control => {
        log('controller', controller.uniqueId, controller, control)
        this.controllers[controller.uniqueId] = controller
        this.registerController(controller)
      })
      controller.onMeshLoadedObservable.add(mesh => {
        const hand = controller.motionController?.handedness
        log('mesh', mesh, hand)
        if (hand == 'right') {
          this.right = mesh
        } else {
          this.left = mesh
        }
      })
    })
    input.onControllerRemovedObservable.add(controller => {
      if (this.controller == controller) {
        this.controller = undefined
      }
      delete this.controllers[controller.uniqueId]
      log('controller removed', controller.uniqueId, controller)
    })
  }

  registerController(controller: WebXRInputSource) {
    const map = this.map
    if (!map) {
      return
    }

    const self = this
    function registerButton(name: string, buttonMap: Button|null|undefined, component: WebXRControllerComponent) {
      log('register button', name, !!buttonMap, !!component)
      if (!buttonMap || !component) {
        return
      }
      let pressed = false
      const observer = component.onButtonStateChangedObservable.add(state => {
        buttonMap.changed && log(`${name} changed`) && buttonMap.changed(state, controller)
        if (state.pressed) {
          !pressed && buttonMap.pressed && log(`${name} pressed`) && buttonMap.pressed(state, controller)
          pressed = true
        } else {
          pressed && buttonMap.release && log(`${name} released`) && buttonMap.release(state, controller)
          pressed = false
        }
      })
      self.disposers.push(() => component.onButtonStateChangedObservable.remove(observer))
    }
  
    const control = controller.motionController!
    if (map.left && control.handedness == 'left') {
      const controlMap = map.left
      registerButton('left-trigger', controlMap.trigger, control.getComponent('xr-standard-trigger'))
      registerButton('left-hold', controlMap.hold, control.getComponent('xr-standard-squeeze'))
      registerButton('x-button', controlMap.x, control.getComponent('x-button'))
      registerButton('y-button', controlMap.y, control.getComponent('y-button'))
    } else if (map.right && control.handedness == 'right') {
      // assign right hand as "main" controller
      this.controller = controller
      const controlMap = map.right
      registerButton('right-trigger', controlMap.trigger, control.getComponent('xr-standard-trigger'))
      registerButton('right-hold', controlMap.hold, control.getComponent('xr-standard-squeeze'))
      registerButton('a-button', controlMap.a, control.getComponent('a-button'))
      registerButton('b-button', controlMap.b, control.getComponent('b-button'))
    }
  }

  setInputs(map: InputMap|null) {
    // remove all existing observers
    this.disposers.forEach(d => d())
    this.disposers.length = 0

    this.map = map ?? undefined

    if (this.map) {
      for (let controller of Object.values(this.controllers)) {
        this.registerController(controller)
      }
    }    
  }

  tick() {
    if (this.onMove && this.controller) {
      this.controller.getWorldPointerRayToRef(this.ray)
      this.onMove(this.ray)
    }
  }
}