// wrapper for calls API endpoints

export type Track = {
	location?: 'local' | 'remote'
	trackName?: string
	sessionId?: string
	mid?: string | null
}

export interface SessionDescription {
	type: 'offer' | 'answer'
	sdp: string
}

export interface ErrorResponse {
	errorCode?: string
	errorDescription?: string
}

export type NewSessionRequest = {
	sessionDescription: SessionDescription
}

export interface NewSessionResponse extends ErrorResponse {
	sessionDescription: SessionDescription
	sessionId: string
}

export type TracksRequest = {
	tracks: Track[]
	sessionDescription?: SessionDescription
}

export interface TracksResponse extends ErrorResponse {
	sessionDescription: SessionDescription
	requiresImmediateRenegotiation: boolean
	tracks?: (Track & ErrorResponse)[]
}

export type RenegotiateRequest = {
	sessionDescription: SessionDescription
}

export interface RenegotiationResponse extends ErrorResponse {}

export type CloseTracksRequest = TracksRequest & {
	force: boolean
}

export interface CallsApi {
  session(init: NewSessionRequest): Promise<NewSessionResponse>
  tracks(sessionId:string, req: TracksRequest): Promise<TracksResponse>
  renegotiate(sessionId: string, req: RenegotiateRequest): Promise<RenegotiationResponse>
}

function getResponse<T>(name: string, res: Response): Promise<T> {
  if (res.status >= 300) {
    throw new Error(`${name} - bad response: ${res.status}`)
  }
  return res.json()
}

export class BasicCallsApi implements CallsApi {
  headers: Headers
  API_BASE: string

  constructor(appId: string, secret: string) {
    this.API_BASE = `https://rtc.live.cloudflare.com/v1/apps/${appId}`
    this.headers = new Headers({
      Authorization: `Bearer ${secret}`,
    })
  }

  async session(init: NewSessionRequest): Promise<NewSessionResponse> {
    console.log('callsApi - session')
    const res = await fetch(`${this.API_BASE}/sessions/new`, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(init),
      }
    )
    console.log('callsApi - session: ', res.status)
    return getResponse('callsapi - session', res)
  }

  async tracks(sessionId: string, req: TracksRequest): Promise<TracksResponse> {
    const res = await fetch(`${this.API_BASE}/sessions/${sessionId}/tracks/new`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(req),
    })
    return getResponse('callsapi - tracks', res)
  }

  async renegotiate(sessionId: string, req: RenegotiateRequest): Promise<RenegotiationResponse> {
    const res = await fetch(`${this.API_BASE}/sessions/${sessionId}/renegotiate`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(req),
    })
    return getResponse('callsapi - renegotiate', res)
  }
}
