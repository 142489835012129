import { 
  Scene, Engine, FreeCamera, Vector3, HemisphericLight, MeshBuilder, SceneLoader, 
  AssetContainer, Mesh,
  WebXRSessionManager,
  StandardMaterial,
  Color3,
  Quaternion,
  WebXRHitTest,
  TransformNode,
  DynamicTexture,
  WebXRControllerComponent,
  WebXRInputSource,
  AbstractMesh,
  Camera,
  Sound,
  Ray,
  Vector2,
  ICanvasRenderingContext,
  Matrix,
} 
from 'babylonjs'
import "babylonjs-loaders"
import earcut from "earcut"
import { InputRouter } from './input'
import { completer, log, wordWrap } from './util'
import { RoomCalls, RoomClient, connect } from './room'
import { NewRecorder } from './recorder'
import { asr } from './asr'
import Calls, { CallStream } from './calls'

// try to get recorder early, to ensure we have permissions
const recorderP = NewRecorder()

const canvas = document.getElementById('view') as HTMLCanvasElement
const engine = new Engine(canvas, true, {
  xrCompatible: true, 
  // stencil: true,
})
window.engine = engine

let scene: Scene
let room: TransformNode

let numNotes = 0

async function load(glb: string): Promise<AssetContainer> {
  const c = await SceneLoader.LoadAssetContainerAsync(glb)
  c.rootNodes.forEach(n => {
    n.setEnabled(false)
    n.getChildMeshes()[0].rotationQuaternion = new Quaternion(0,1.0,0,0)
    n.getChildMeshes()[0].position.y = -0.6
  })
  return c
}

function inject(src: AssetContainer): Mesh {
  const models = src.instantiateModelsToScene()
  log('inject', models)
  const mesh = models.rootNodes[0] as Mesh
  mesh.parent = room
  mesh.setEnabled(true)
  mesh.renderingGroupId = 1
  return mesh
}

const assets: any = {}
window.assets = assets

async function loadAssets() {
  if (!assets.avatar) {
    //const avatarRoot = await SceneLoader.ImportMeshAsync("", "https://models.readyplayer.me/", "63d859d960d1b8cc82e00091.glb", scene)
    assets.avatar = await load("https://models.readyplayer.me/661af1ceaaa958d48f2f5e86.glb?quality=low&useHands=false")
    log('assets loaded')
  }
}

declare global {
  interface Window { 
    engine: Engine
    scene: Scene
    assets: Record<string, AssetContainer>
    avatars: Record<number, Avatar>
    notes: Record<number, Note>
  }
}

type Id = {
  id: number
}

type Timed<T> = {
  [Prop in keyof T as `${string & Prop}_t`]: number
}

type Optional<T> = {
  [Prop in keyof T]?: T[Prop]
}

type Serial<T> = Id & Timestamp & {
  [Prop in keyof T]: 
    T[Prop] extends Vector3|Quaternion ? number[] : 
    T[Prop] extends Vector3|Quaternion|undefined ? number[]|undefined :
    T[Prop]
}

type Timestamp = {
  ts: number
}

type AvatarConst = {
  skin: string
  mesh: Mesh|null
}
type AvatarBase = {
  pos: Vector3
  rot: Quaternion

  leftPos: Vector3
  leftRot: Quaternion

  rightPos: Vector3
  rightRot: Quaternion
}
type Avatar = Id & AvatarConst & AvatarBase & Timed<AvatarBase>

type NoteConst = {
  texture: DynamicTexture,
  mesh: Mesh
}
type NoteBase = {
  pos: Vector3
  target: Vector3
  targetFuture: number
  rot: Quaternion
  text: string[]
  size: number
}
type Note = Id & NoteConst & NoteBase & Timed<NoteBase>

function drawText(texture: DynamicTexture, text: string[], size: number) {
  const ctx = texture.getContext() as any
  ctx.textAlign = "center"
  let y = 100 - (text.length * (size+2))/2
  const font = `bold ${size}px arial`
  texture.drawText('', 0, 0, font, null, 'yellow', true, false)
  for (let m of text) {
    texture.drawText(m, 100, y, font, "black", null, true, false)
    y += size+2
  }
  texture.update()
}

const avatars = new Map<number,Avatar>()
window.avatars = avatars
const avatarCalls = new Map<number, MediaStream>()
window.avatarCalls = avatarCalls
const notes = new Map<number,Note>()
window.notes = notes

let board: Mesh|null = null
let boardPaint: DynamicTexture|null = null
let boardCtx: ICanvasRenderingContext|null = null

// assigned after connection
let me: number = -1

function newNoteId() { 
  return Date.now()
}

async function loadAvatarMesh(a: Avatar) {
  if (a.id === me || a.mesh) {
    // log('skipped mesh loading')
    return
  }
  await loadAssets()
  const mesh = inject(assets.avatar)
  a.mesh = mesh
  mesh.position.copyFrom(a.pos)
  mesh.rotationQuaternion?.copyFrom(a.rot)
  log('avatar meshed', a.id)

  // if stream loaded first, attach it now
  attachAvatarStream(a)
}

function attachAvatarStream(a: Avatar) {
  log('attachAvatarStream', a?.id, avatarCalls.get(a?.id))
  if (!a || !a.mesh) {
    // can only attach when both mesh & stream exist
    return
  }
  const stream = avatarCalls.get(a.id)
  if (!stream) {
    // make sure stream exists
    return
  }

  log('connected avatar audio', a.id, stream.id)
  const audio = document.createElement('audio')
  audio.srcObject = stream
  const sound = new Sound(`avatar${a.id}`, audio.srcObject, scene, null, {
    streaming: true,
    autoplay: true,
    spatialSound: true,
    loop: true,
  })
  sound.attachToMesh(a.mesh)
}

const dispatcher: Record<string, Function> = {
  createAvatar(data: AvatarConst & Serial<AvatarBase>) {
    if (avatars.get(data.id)) {
      // log('switch to update')
      dispatch({...data, cmd:'updateAvatar'})
      return
    }
    const {id, ts, skin, pos, rot, leftPos, leftRot, rightPos, rightRot} = data
    const a: Avatar = {
      id,
      skin,
      pos: Vector3.FromArray(pos),
      pos_t: ts,
      rot: Quaternion.FromArray(rot),
      rot_t: ts,
      leftPos: Vector3.FromArray(leftPos),
      leftPos_t: ts,
      leftRot: Quaternion.FromArray(leftRot),
      leftRot_t: ts,
      rightPos: Vector3.FromArray(rightPos),
      rightPos_t: ts,
      rightRot: Quaternion.FromArray(rightRot),
      rightRot_t: ts,
      mesh: null,
    }
    avatars.set(id, a)
    log('created avatar', a.id)

    // background load avatar
    loadAvatarMesh(a)
  },

  updateAvatar({id, ts, pos, rot, leftPos, leftRot, rightPos, rightRot}: Serial<Optional<AvatarBase>>) {
    const a = avatars.get(id)
    if (!a) {
      return false
    }
    if (pos != undefined && ts>a.pos_t) {
      a.pos.fromArray(pos)
      a.pos_t = ts
      a.mesh?.position?.copyFrom(a.pos)
    }
    if (rot != undefined && ts>a.rot_t) {
      a.rot.fromArray(rot)
      a.rot_t = ts
      a.mesh?.rotationQuaternion?.copyFrom(a.rot)
    }
    if (leftPos != undefined && ts>a.leftPos_t) {
      a.leftPos.fromArray(leftPos)
      a.leftPos_t = ts
    }
    if (leftRot != undefined && ts>a.leftRot_t) {
      a.leftRot.fromArray(leftRot)
      a.leftRot_t = ts
    }
    if (rightPos != undefined && ts>a.rightPos_t) {
      a.rightPos.fromArray(rightPos)
      a.rightPos_t = ts
    }
    if (rightRot != undefined && ts>a.rightRot_t) {
      a.rightRot.fromArray(rightRot)
      a.rightRot_t = ts
    }
  },

  deleteAvatar({id}: Serial<void>) {
    const a = avatars.get(id)
    if (!a) {
      return false
    }
    a.mesh?.dispose()
    avatars.delete(id)
  },

  createNote({id, ts, pos, rot, text, size}: Serial<NoteBase>) {
    const name = `note${id}`
    const noteText = new DynamicTexture(name, { width: 200, height: 200 }, scene)
    drawText(noteText, text, size)
    const noteMat = new StandardMaterial(name, scene)
    noteMat.diffuseTexture = noteText
    const plane = MeshBuilder.CreatePlane(name, { width: 0.2, height: 0.2 }, scene)
    plane.material = noteMat
    plane.isPickable = true
    plane.position.fromArray(pos)
    plane.rotationQuaternion = Quaternion.FromArray(rot)
    plane.parent = room
    plane.renderingGroupId = 1

    const note: Note = {
      id,
      pos: Vector3.FromArray(pos),
      pos_t: ts,
      target: Vector3.Zero(),
      target_t: ts,
      targetFuture: 0,
      targetFuture_t: ts,
      rot: Quaternion.FromArray(rot),
      rot_t: ts,
      text,
      text_t: ts,
      size,
      size_t: ts,
      mesh: plane,
      texture: noteText,
    }
    plane.metadata = { note }

    notes.set(id, note)
  },

  updateNote({id, ts, pos, rot, text, size}: Serial<Optional<NoteBase>>) {
    // log('updating note', id)
    const n = notes.get(id)
    if (!n) {
      log('missing note', id, typeof(id))
      return false
    }
    if (pos != undefined && ts>n.pos_t) {
      n.pos.fromArray(pos)
      n.mesh.position.fromArray(pos)
      n.pos_t = ts
    }
    if (rot != undefined && ts>n.rot_t) {
      n.rot.fromArray(rot)
      n.mesh.rotationQuaternion?.fromArray(rot)
      n.rot_t = ts
    }
    let redraw = false
    if (text != undefined && ts>n.text_t) {
      n.text = text
      n.text_t = ts
      redraw = true
    }
    if (size != undefined && ts>n.size_t) {
      n.size = size
      n.size_t = ts
      redraw = true
    }
    if (redraw) {
      drawText(n.texture, n.text, n.size)
    }
  },

  deleteNote({id, ts}: Serial<void>) {
    const n = notes.get(id)
    if (!n) {
      return
    }
    n.texture.dispose()
    n.mesh.material?.dispose()
    n.mesh.dispose()
    notes.delete(id)
  },

  paint({x1,y1, x2, y2}: {x1:number, y1:number, x2:number, y2: number}) {
    if (!board || !boardCtx || !boardPaint) {
      return
    }
    boardCtx.strokeStyle = '#000000'
    boardCtx.lineWidth = 3
    boardCtx.lineCap = 'round'
    boardCtx.beginPath()
    boardCtx.moveTo(x1, y1)
    boardCtx.lineTo(x2, y2)
    boardCtx.closePath()
    boardCtx.stroke()
    boardPaint.update()
  },
}

function dispatch(cmd: any): boolean {
  const impl = dispatcher[cmd.cmd]
  if (!impl) {
    log('Bad command:', cmd.cmd)
    return false
  }
  const ret = impl(cmd)
  return ret === undefined || ret
}
window.dispatch = dispatch

let client: RoomClient

function exec(cmd: any) {
  if (dispatch(cmd)) {
    const event = cmd.cmd
    // log('send', cmd)
    client && client.emit(event, cmd)
  }
}

async function main() {
  log('v28')

  const scene = new Scene(engine)
  window.scene = scene

  // scene.detachControl()

  log('scene', scene)
  const camera = new FreeCamera('camera1', new Vector3(2,1.5,-1), scene)
  camera.setTarget(new Vector3(0, 1.6, 0))
  // camera.attachControl(canvas, false)

  const vrSupported = await WebXRSessionManager.IsSessionSupportedAsync('immersive-vr')
  const arSupported = await WebXRSessionManager.IsSessionSupportedAsync('immersive-ar')

  log('vr', vrSupported, 'ar', arSupported)

  const sessionMode = 'immersive-ar'
  // const sessionMode = arSupported ? 'immersive-ar' : vrSupported? 'immersive-vr' : 'inline'
  log('sessionMode', sessionMode)
  // const ground = MeshBuilder.CreateGround('ground1', { width: 12, height: 12, subdivisions:2, updatable: false}, scene)
  const xr = await scene.createDefaultXRExperienceAsync({
      // floorMeshes: [ground],
      // handSupportOptions: {
      // },
      uiOptions: {
        sessionMode,
        referenceSpaceType: "local-floor",
      },
      // disableTeleportation: true,
  })
  window.xr = xr
  const featuresManager = xr.baseExperience.featuresManager

  log('initializing inputs')
  const inputs = new InputRouter(xr.input)

  room = new TransformNode('room', scene)
  window.room = room
  room.rotationQuaternion = new Quaternion()

  const occluder = MeshBuilder.CreateBox('occluder', {
    width: 20,
    depth: 20,
    height: 2.74,
  })
  occluder.parent = room
  occluder.position.x = 0
  occluder.position.y = 1.37
  occluder.position.z = -9.9
  occluder.flipFaces(true)
  const om = new StandardMaterial('occluder', scene)
  om.forceDepthWrite = true
  occluder.material = om
  occluder.visibility = 0.000001
  occluder.renderingGroupId = 0

  board = MeshBuilder.CreateBox('board', {
    width: 1.8,
    depth: 1.2,
    height: 0.01,
  }, scene)
  board.parent = room
  board.rotation.x = -Math.PI/2
  board.rotation.y = Math.PI
  board.position.y = 1.3
  const boardMat = new StandardMaterial("board", scene)
  const boardSize = 1024
  boardPaint = new DynamicTexture("paint", boardSize, scene)
  boardMat.diffuseTexture = boardPaint
  boardCtx = boardPaint.getContext()
  boardCtx.fillStyle = '#ffffff'
  boardCtx.fillRect(0,0,boardSize,boardSize)
  boardPaint.update()
  board.material = boardMat
  board.renderingGroupId = 1

  // const marker = MeshBuilder.CreateBox('marker', {
  //   width: 1.8,
  //   depth: 1.2,
  //   height: 0.01,
  // }, scene)
  // marker.isVisible = false;
  // const markerMat = new StandardMaterial("marker", scene)
  // markerMat.alpha = 0.5
  // markerMat.diffuseColor = Color3.Green()
  // marker.material = markerMat
  // marker.rotationQuaternion = new Quaternion();

  // const p = MeshBuilder.CreateSphere('point', {
  //   segments:12, diameter: 0.02
  // }, scene)
  // p.isVisible = true
  // p.material = markerMat
  // p.position.z = 1.0
  // p.position.y = 1.0

  const light = new HemisphericLight('light1', new Vector3(0,3,0), scene)
  light.parent = room

  const sphere = MeshBuilder.CreateSphere('sphere1', { segments: 12, diameter: 2 }, scene)
  sphere.position.y = 3
  sphere.parent = room
  sphere.renderingGroupId = 1

  scene.setRenderingAutoClearDepthStencil(1, false, false, false)

  // dispatch({ 
  //   cmd: 'createNote', 
  //   pos: new Vector3(0, 1.8, -0.02).asArray(),
  //   rot: new Quaternion().asArray(),
  //   text: ['Hello, World'],
  //   size: 36,
  // })

  engine.runRenderLoop(() => {
    inputs.tick()
    scene.render()
  })
  window.addEventListener('resize', () => engine.resize())
  window.addEventListener("keydown", (ev: KeyboardEvent) => {
    if (ev.shiftKey && ev.ctrlKey && ev.altKey && ev.code === 'KeyI') {
      if (scene.debugLayer.isVisible()) {
        scene.debugLayer.hide()
      } else {
        scene.debugLayer.show()
      }
    }
    if (!ev.shiftKey && !ev.ctrlKey && !ev.altKey && ev.code === 'KeyW') {
      const camera = scene.activeCamera!
      const pos = Vector3.TransformCoordinates(new Vector3(0,0,0.2), camera.getWorldMatrix())
      camera.position.copyFrom(pos)
    }
    if (!ev.shiftKey && !ev.ctrlKey && !ev.altKey && ev.code === 'KeyS') {
      const camera = scene.activeCamera!
      const pos = Vector3.TransformCoordinates(new Vector3(0,0,-0.2), camera.getWorldMatrix())
      camera.position.copyFrom(pos)
    }
  })

  const connected = completer<void>()
  client = connect('test', {
    init({id,start}:{id: number, start: number}) {
      me = id
      log('room initialized', id, start)
      connected.complete()
    },
    pong({start}:{start: number}) {
      const rtt = Date.now() - start
      console.log(`ping = ${rtt}`)
    },
    _else(data: any) {
      if (['updateAvatar','createAvatar'].indexOf(data._type) == -1) {
        log('got', data)
      }
      dispatch(data)
    },
  })

  setInterval(() => {
    client.emit('ping', {
      start: Date.now(),
    })
  }, 30000)
  
  log('loading assets')
  loadAssets()

  async function anchorBoard() {
    if (!arSupported) {
      log('AR not available')
      return
    }
    const hit = featuresManager.enableFeature(WebXRHitTest, "latest") as WebXRHitTest
    const hitScale = new Vector3()
    const hitQuat = new Quaternion()
    const hitPos = new Vector3()
    const hitObserver = hit.onHitTestResultObservable.add((results) => {
      if (results.length) {
        const hitTest = results[0];
        hitTest.transformationMatrix.decompose(hitScale, hitQuat, hitPos);
        
        const extra = new Vector3(-Math.PI/2, 0, 0).toQuaternion()
        const eulers = hitQuat.toEulerAngles()
        eulers.x = Math.PI/2

        room.position.copyFrom(hitPos)
        room.position.y = 0
        room.rotationQuaternion!.copyFrom(eulers.toQuaternion().multiply(extra))
      }
    })

    const exit = completer()
    inputs.setInputs({
      right: {
        trigger: { pressed: exit.complete },
        a: { pressed: exit.complete },
      },
    })

    await exit

    inputs.setInputs(null)

    hit.onHitTestResultObservable.remove(hitObserver)
    featuresManager.disableFeature(WebXRHitTest)

    log('board anchored')
  }

  try {
    // try to anchor the board if in AR
    await anchorBoard()
  } catch (err) {
    log(err)
  }

  log('connecting to room')
  client && await connected

  log('establishing microphone')
  const recorder = await recorderP

  async function audio() {
    log('connecting room audio')
    const calls = new RoomCalls(client)
    await calls.init(recorder.stream)
    calls.streamsObservable.add(({ clientId, stream }: {clientId: number, stream: MediaStream }) => {
      log('streamsObservable add', clientId, stream.id)
      avatarCalls.set(clientId, stream)

      const a = avatars.get(clientId)
      if (!a) {
        return
      }

      // if mesh was created first, attach the stream now
      log('adding client audio', clientId, stream.id)
      attachAvatarStream(a)
    })
  }
  audio()

  function interact() {
    let grabbed: AbstractMesh|null = null
    
    const roomInverse = room.getWorldMatrix().clone().invert()
    log('interacting')

    let lastUpdate = 0

    function syncAvatar(cmd: string = 'updateAvatar') {
      const now = Date.now()
      // log('syncAvatar', now, lastUpdate)
      if (now <= lastUpdate + 20) {
        // too fast update, skip
        return
      }
      lastUpdate = now

      // project camera into room
      const headPos = Vector3.TransformCoordinates(scene.activeCamera!.globalPosition, roomInverse)
      const headRot = scene.activeCamera!.absoluteRotation.multiply(room.rotationQuaternion!.clone().invert())

      // send update
      exec({
        cmd,
        id: me,
        pos: headPos.asArray(),
        rot: headRot.asArray(),
        leftPos: [0.0,0.0,0.0],
        leftRot: [1.0,0.0,0.0,0.0],
        rightPos: [0.0,0.0,0.0],
        rightRot: [1.0,0.0,0.0,0.0],
        ts: lastUpdate,
      })
    }
    syncAvatar('createAvatar')
    scene.activeCamera?.onViewMatrixChangedObservable.add(() => syncAvatar())
    setInterval(() => syncAvatar('createAvatar'), 5000)

    function startNote(state: WebXRControllerComponent, controller: WebXRInputSource) {
      if (inputs.right) {
        const pos = Vector3.TransformCoordinates(inputs.right.parent!.position, roomInverse)
        const rot = new Quaternion()
        const id = newNoteId()
        exec({
          cmd: 'createNote',
          id,
          pos: pos.asArray(),
          rot: rot.asArray(),
          text: ['...'],
          size: 24,
          ts: Date.now(),
        })
        log('created note', pos)
        grabbed = notes.get(id)!.mesh
        grabbed.setParent(controller.motionController!.rootMesh)
        recorder.start(async data => {
          log('start asr', pos)
          const res = await asr(data)
          log('recognized note', pos)
          const text = wordWrap(res.text, 18).split('\n')
          exec({
            cmd: 'updateNote',
            id,
            text,
            ts: Date.now()
          })
        })
      }
    }

    function endNote() {
      recorder.stop()

      if (!grabbed) {
        return
      }

      grabbed.setParent(room)
      const note = grabbed.metadata.note
      if (!note) {
        return
      }

      exec({
        cmd: 'updateNote',
        id: note.id,
        pos: grabbed.position.asArray(),
        rot: grabbed.rotationQuaternion!.asArray(),
        ts: Date.now(),
      })
    }

    function grabNote(state: WebXRControllerComponent, controller: WebXRInputSource) {
      const mesh = xr.pointerSelection?.getMeshUnderPointer(controller.uniqueId)
      if (mesh && mesh.metadata?.note) {
        grabbed = mesh
        grabbed.setParent(controller.motionController!.rootMesh)
        grabbed.position.set(0,0,0)
      }
    }

    function releaseNote(state: WebXRControllerComponent, controller: WebXRInputSource) {
      if (!grabbed) {
        return
      }

      grabbed.setParent(null)
      // grabbed.setParent(room)

      const note = grabbed.metadata.note
      if (!note) {
        return
      }

      const ray = Ray.Zero()
      inputs.controller?.getWorldPointerRayToRef(ray)
      const pick = ray.intersectsMesh(board!)
      log('ray', ray.direction, !!pick, pick?.pickedPoint)
      if (pick && pick.pickedPoint) {
        // const inv = new Matrix()
        // room.getWorldMatrix().invertToRef(inv)
        // grabbed.position.copyFrom(Vector3.TransformCoordinates(pick.pickedPoint, inv))
        grabbed.position.copyFrom(pick.pickedPoint)
      }
      grabbed.setParent(room)
      grabbed.position.z -= 0.04
      // log('release 4', grabbed.position)

      exec({
        cmd: 'updateNote',
        id: note.id,
        pos: grabbed.position.asArray(),
        rot: grabbed.rotationQuaternion!.asArray(),
        ts: Date.now(),
      })

      grabbed = null
    }

    let prev = new Vector2(-1,0)
    function paintStart() {
      inputs.onMove = paint
      prev.x = -1
    }
    function paintEnd() {
      inputs.onMove = undefined
    }
  
    function paint(ray: Ray) {
      const pick = ray.intersectsMesh(board!)
      if (pick) {
        const p = pick.getTextureCoordinates()
        if (p) {
          const x = p.x*boardSize
          const y = (1-p.y)*boardSize
          if (prev.x < 1) {
            prev.x = x
            prev.y = y
          }
          exec({
            cmd: 'paint',
            x1: prev.x,
            y1: prev.y,
            x2: x,
            y2: y,
          })
          prev.x = x
          prev.y = y
        }
      }
    }

    inputs.setInputs({
      left: {
      },
      right: {
        trigger: { 
          pressed: paintStart,
          release: paintEnd,
        },
        hold: { 
          pressed: grabNote,
          release: releaseNote,
        },
        a: { 
          pressed: startNote,
          release: endNote,
        },
      }
    })
  }

  interact()
}

main()