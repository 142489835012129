export function log(...o: any[]) {
  console.log(...o)
  // fetch(`/log/${encodeURI(o.map(i => String(i)).join('_'))}}`)
  return o[0]
}

export type Completer<T> = Promise<T> & {
  complete: (val: T) => void
}

export function completer<T>(): Completer<T> {
  let resolve: ((val: T) => void)|null = null
  const promise = new Promise<T>((res,rej) => resolve = res) as Completer<T>
  promise.complete = (val: T) => resolve && resolve(val)
  return promise
}

export function wordWrap(str: string, max: number, br: string = '\n') {
  return str.replace(new RegExp(`(?![^\\n]{1,${max}}$)([^\\n]{1,${max}})\\s`, 'g'), `$1${br}`)
}

// ensure implicit async handlers log errors
export function safeResult(ret: any) {
  if (ret?.catch) {
    ret.catch((err: any) => {
      log('error', err)
      throw err
    })
  }
  return ret
}