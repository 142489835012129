// Client Implementation of Multiplexed Media/Audio

import { BasicCallsApi, CallsApi, Track } from "./callsApi";

export type CallStream = {
  tracks: Track[]
}

export class Calls {
  api: CallsApi
  peerConnection: RTCPeerConnection
  sessionId: string = ''

  constructor(api: CallsApi) {
    this.api = api

    this.peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.cloudflare.com:3478" }],
      bundlePolicy: "max-bundle",
    });

    // fake intiial track
    this.peerConnection.addTransceiver("audio", {
      direction: "inactive",
    });
  }

  async init() {
    // create an offer and set it as the local description
    await this.peerConnection.setLocalDescription(
      await this.peerConnection.createOffer()
    );
    const { sessionId, sessionDescription } = await this.api.session({
      sessionDescription: this.peerConnection.localDescription?.toJSON(),
    })
    this.sessionId = sessionId

    const connected = new Promise<void>((res, rej) => {
      // timeout after 5s
      setTimeout(rej, 5000)
      const handler = () => {
        if (this.peerConnection.iceConnectionState === "connected") {
          this.peerConnection.removeEventListener("iceconnectionstatechange", handler)
          res()
        }
      }
      this.peerConnection.addEventListener( "iceconnectionstatechange", handler)
    });

    // Once both local and remote descriptions are set, the ICE process begins
    await this.peerConnection.setRemoteDescription(sessionDescription);
    // Wait until the peer connection's iceConnectionState is "connected"
    await connected;
  }

  async sendStream(stream: MediaStream): Promise<CallStream> {
    const transceivers = stream.getTracks().map((track) => this.peerConnection.addTransceiver(track, { direction: "sendonly" }))

    // Now that the peer connection has tracks, the next step is to create and set a
    // new offer as the local description. This offer will contain the new tracks in
    // its session description.
    await this.peerConnection.setLocalDescription(await this.peerConnection.createOffer())

    // Send the local session description to the Calls API, it will
    // respond with an answer and trackIds.
    const { sessionDescription } = await this.api.tracks(this.sessionId, {
      sessionDescription: {
        sdp: this.peerConnection.localDescription!.sdp,
        type: "offer",
      },
      tracks: transceivers.map(({ mid, sender }) => ({
        location: "local",
        mid,
        trackName: sender.track?.id,
      })),
    })

    // We take the answer we got from the Calls API and set it as the
    // peer connection's remote description.
    await this.peerConnection.setRemoteDescription(new RTCSessionDescription(sessionDescription))

    return { 
      tracks: transceivers.map(({ sender }) => ({
        location: "remote",
        trackName: sender.track?.id,
        sessionId: this.sessionId,
      }))
    }
  }

  async getStream(stream: CallStream): Promise<MediaStream> {
    // We're going to modify the remote session and pull these tracks
    // by requesting an offer from the Calls API with the tracks we
    // want to pull.
    const pullResponse = await this.api.tracks(this.sessionId, {
      tracks: stream.tracks,
    })

    // We set up this promise before updating local and remote descriptions
    // so the "track" event listeners are already in place before they fire.
    const resolvingTracks = Promise.all(
      pullResponse.tracks!.map(
        ({ mid }) =>
          // This will resolve when the track for the corresponding mid is added.
          new Promise<MediaStreamTrack>((res, rej) => {
            setTimeout(rej, 5000);
            const handler = ({ transceiver, track }: RTCTrackEvent) => {
              if (transceiver.mid !== mid) return;
              this.peerConnection.removeEventListener("track", handler)
              res(track);
            };
            this.peerConnection.addEventListener("track", handler)
          })
      )
    );

    // Handle renegotiation, this will always be true when pulling tracks
    if (pullResponse.requiresImmediateRenegotiation) {
      // We got a session description from the remote in the response,
      // we need to set it as the remote description
      this.peerConnection.setRemoteDescription(pullResponse.sessionDescription)
      // Create and set the answer as local description
      await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer())
      // Send our answer back to the Calls API
      const reneg = await this.api.renegotiate(this.sessionId, {
        sessionDescription: {
          sdp: this.peerConnection.currentLocalDescription!.sdp,
          type: "answer",
        },
      })
      if (reneg.errorCode) {
        throw new Error(reneg.errorDescription)
      }
    }

    // Now we wait for the tracks to resolve
    const pulledTracks = await resolvingTracks;

    const remoteStream = new MediaStream();
    pulledTracks.forEach((t) => remoteStream.addTrack(t))

    return remoteStream
  }
}

window.Calls = Calls
window.BasicCallsApi = BasicCallsApi