const url = new URL(document.URL)
url.pathname = `/api/asr`

export type ASRResult = {
  text: string
  word_count: number
  words: { 
    word: string
    start: number
    end: number
  }[]
}

export async function asr(data: Blob): Promise<ASRResult> {
  const res = await fetch(url, {
    method: 'POST',
    body: data
  })
  if (!res.ok) {
    throw Error(`Speech not recognized: ${res.statusText}`)
  }
  return await res.json()
}